export const SvgLogo = (props: { width?: number; height?: number; class?: string }) => {
  const { width, height } = props;
  return (
    <svg class={props.class} width={width ?? 256} height={height ?? 48} viewBox="0 0 267 50">
      <g fill-rule="evenodd">
        <g>
          <path d="M22.363 49.688V31.562h7.663c6.856 0 12.65-5.798 12.65-12.662 0-6.975-5.675-12.65-12.65-12.65-1.258 0-2.48.178-3.63.527-5.323 1.594-9.033 6.578-9.033 12.123v25.631h-6.25V18.9c0-8.284 5.543-15.73 13.479-18.106C26.32.268 28.153 0 30.026 0c10.42 0 18.9 8.479 18.9 18.9 0 10.251-8.655 18.913-18.9 18.913l-1.413.026v11.849h-6.25M7.595 37.735L0 37.735 0 31.573 7.598 31.573z" />
          <path d="M34.94 19.764c-.421 0-1.797.101-1.797-.335 0-.436 1.376-1.245 1.797-1.245.421 0 .763.355.763.791 0 .436-.342.789-.763.789zm.379 1.552c-.019.05-.044.097-.082.147-.036.048-.082.097-.142.145-.12.092-.288.18-.527.255l-.863-.002H32.75l.981-1.126h1.473c0-.001.221.278.115.581zm-3.667-.798H27.05c-.479 0-.869-.593-.869-1.322h6.34c0 .73-.389 1.322-.869 1.322zm-.184 1.343h-4.232c-.441 0-.799-.358-.799-.798h5.829c0 .44-.357.798-.798.798zm-6.18 0h-1.152c-.238-.073-.407-.16-.526-.253-.061-.048-.107-.098-.144-.147-.036-.048-.062-.097-.08-.146-.107-.302.115-.581.115-.581h1.47l.983 1.126h-.665zm-2.287-2.886c0-.436.341-.791.764-.791.42 0 1.797.809 1.797 1.245 0 .436-1.377.335-1.797.335-.422 0-.764-.353-.764-.789zm3.279-3.497c.176-.147.484-.258.636-.258h4.871c.154 0 .462.111.637.258l1.64 1.588h-9.422l1.638-1.588zm9.129 1.903l-.029-.03-.484-.468c.401-.092.834-.428.834-.773 0-.404-.557-.546-1.035-.546-.499 0-.556.342-.563.582l-1.25-1.203c-.253-.253-.734-.45-1.094-.45h-4.872c-.36 0-.841.197-1.095.45l-1.25 1.203c-.006-.24-.065-.582-.562-.582-.478 0-1.035.142-1.035.546 0 .345.432.681.835.773l-.485.467c-.012.011-.019.021-.028.031-.518.444-.857 1.265-.857 1.862v1.77c0 .432.17.822.444 1.113v.687c0 .402.336.73.75.73h.907c.414 0 .749-.328.749-.73v-.175H33.413v.175c0 .402.337.73.752.73h.906c.415 0 .75-.328.75-.73v-.687c.274-.29.444-.68.444-1.112v-1.771c.001-.597-.339-1.418-.856-1.862z" />
        </g>
        <path
          fill-rule="nonzero"
          d="M70.679 21.06v-8.093h4.425c3.15 0 4.545 1.515 4.545 4.067 0 2.471-1.395 4.026-4.545 4.026h-4.425zm14.711-4.026c0-4.824-3.349-8.612-10.047-8.612H65.097V36.25h5.582V25.565h4.664c7.176 0 10.047-4.305 10.047-8.531zm2.512 8.093c0 6.897 4.465 11.482 10.046 11.482 3.509 0 6.02-1.675 7.336-3.588v3.229h5.621V14.163h-5.62v3.15c-1.317-1.834-3.749-3.509-7.297-3.509-5.621 0-10.086 4.426-10.086 11.323zm17.382.08c0 4.186-2.79 6.498-5.86 6.498-2.99 0-5.821-2.392-5.821-6.578s2.83-6.419 5.82-6.419c3.07 0 5.861 2.312 5.861 6.499zm20.492 4.385l-5.7-15.429h-6.26l8.85 21.529-4.784 11.003h5.94l13.675-32.532h-5.94l-5.78 15.429zm13.236-16.665h7.416V36.25h5.581V12.927h7.416V8.422h-20.413v4.505zm30.06 12.32c0-4.266 1.994-5.542 5.303-5.542h1.475v-5.86c-3.07 0-5.382 1.474-6.777 3.747v-3.429h-5.582V36.25h5.582V25.246zm10.526 11.003h5.582V14.163h-5.582V36.25zm2.83-24.718c1.954 0 3.43-1.435 3.43-3.27 0-1.833-1.476-3.269-3.43-3.269-1.993 0-3.428 1.436-3.428 3.27s1.435 3.269 3.429 3.269zm13.835 5.82v-3.189h-5.582v32.612h5.582V33.1c1.355 1.794 3.827 3.509 7.296 3.509 5.661 0 10.086-4.585 10.086-11.482 0-6.897-4.425-11.323-10.086-11.323-3.429 0-5.98 1.675-7.296 3.549zm11.681 7.775c0 4.186-2.83 6.578-5.86 6.578-2.99 0-5.821-2.312-5.821-6.498 0-4.187 2.83-6.499 5.82-6.499 3.03 0 5.861 2.233 5.861 6.419zm15.35-7.774v-3.19h-5.582v32.612h5.581V33.1c1.356 1.794 3.828 3.509 7.296 3.509 5.661 0 10.087-4.585 10.087-11.482 0-6.897-4.426-11.323-10.087-11.323-3.428 0-5.98 1.675-7.296 3.549zm11.68 7.774c0 4.186-2.83 6.578-5.86 6.578-2.99 0-5.82-2.312-5.82-6.498 0-4.187 2.83-6.499 5.82-6.499 3.03 0 5.86 2.233 5.86 6.419zm8.333 0c0 6.897 4.465 11.482 10.047 11.482 3.508 0 6.02-1.675 7.336-3.588v3.229h5.62V14.163h-5.62v3.15c-1.316-1.834-3.748-3.509-7.296-3.509-5.622 0-10.087 4.426-10.087 11.323zm17.383.08c0 4.186-2.791 6.498-5.861 6.498-2.99 0-5.82-2.392-5.82-6.578s2.83-6.419 5.82-6.419c3.07 0 5.86 2.312 5.86 6.499z"
        />
      </g>
    </svg>
  );
};
