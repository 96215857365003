import { SvgLogo } from "@/components/SvgLogo";
import { Loading } from "@twjs/vue-loading";
import { defineComponent, PropType } from "vue";

const Success = defineComponent({
  render() {
    return (
      <div class="w-2/3 text-center">
        <h1 class="color-gray text-5xl font-bold text-color welcome_heading">
          Welcome to <span class="label p-1 px-2 rounded-lg primary-bg text-white">Paytrippa</span>
        </h1>
        <p class="mt-5 text-2xl text-color font-semibold welcome_text">We are glad you are here!</p>
        <primary-button
          class="w-4/12 mx-auto mt-4 justify-center welcome_button"
          text="Sign in"
          onClick={() => this.$router.push("/signin")}
        />
      </div>
    );
  },
});

const Fail = defineComponent({
  props: {
    messsage: { type: String, required: true },
    sendMessage: { type: Object as PropType<{ isSuccess: boolean; message: string }>, required: false },
    onClick: { type: Function, required: true },
  },
  render() {
    return (
      <div class="w-2/3 text-center">
        <h1 class="color-gray text-5xl font-bold text-color welcome_heading">Oops!</h1>
        <p class="mt-5 text-2xl text-color font-semibold welcome_text">{this.messsage}</p>
        <p class="mt-5 text-1xl text-color font-semibold welcome_text"> Please click to get new verification email</p>
        <primary-button class="w-4/12 mx-auto mt-4 justify-center welcome_button" text="Resend Email" onClick={() => this.onClick()} />
        {this.sendMessage && (
          <p class={`mt-2 ${this.sendMessage.isSuccess ? "text-green-600" : "text-red-600"}`}>{this.sendMessage.message}</p>
        )}
      </div>
    );
  },
});

export default defineComponent({
  props: {
    $loading: { type: Object as PropType<Loading>, required: true },
  },
  data() {
    return {
      errorMesasge: "",
      sendMessage: undefined as any,
      status: -1,
    };
  },
  mounted() {
    this.active();
  },
  methods: {
    setActiveError(type: number) {
      if (type === 1) {
        this.errorMesasge = "Your verification email is expired!";
        this.status = 2;

        return;
      }

      if (type === 2) {
        this.errorMesasge = "Something wrong! We cannot active your account!";
        this.status = 2;
      }
    },
    async active() {
      this.$loading.show();
      const token = this.$route.query.token;

      try {
        const response = await fetch(`${process.env.VUE_APP_HTTP_ENDPOINT}/customer/active`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
          }),
        });

        if (!response.ok && response.status === 512) {
          return this.setActiveError(1);
        }

        if (!response.ok) {
          return this.setActiveError(2);
        }

        const body = await response.json();

        if (body.code === 512) {
          return this.setActiveError(1);
        }

        if (body.code !== 200) {
          return this.setActiveError(2);
        }

        this.status = 1;
      } catch (e: any) {
        this.setActiveError(2);
      } finally {
        this.$loading.hide();
      }
    },
    async resend() {
      this.$loading.show();
      const token = this.$route.query.token;

      try {
        const response = await fetch(`${process.env.VUE_APP_HTTP_ENDPOINT}/customer/re-send`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            token: token,
          }),
        });

        if (!response.ok) {
          this.sendMessage = {
            isSuccess: false,
            message: "Something wrong! Please try again.",
          };

          return;
        }

        const body = await response.json();

        if (!body.code || body.code !== 200) {
          this.sendMessage = {
            isSuccess: false,
            message: "Something wrong! Please try again.",
          };

          return;
        }

        this.sendMessage = {
          isSuccess: true,
          message: "Your verification email has been sent! Please check your verify email to complete registration.",
        };
      } catch (e: any) {
        this.sendMessage = {
          isSuccess: false,
          message: "Something wrong! Please try again.",
        };
      } finally {
        this.$loading.hide();
      }
    },
  },
  render() {
    return (
      <div class="flex flex-col justify-between py-4 w-full h-screen welcome-container">
        <div class="w-full">
          <SvgLogo class="ml-8 mt-4 w-56 text-primary-600 fill-current welcome_logo" />
          <div class="flex justify-center mt-16">
            {this.status === -1 ? (
              <div></div>
            ) : this.status === 1 ? (
              <Success />
            ) : (
              <Fail messsage={this.errorMesasge} sendMessage={this.sendMessage} onClick={this.resend} />
            )}
          </div>
        </div>
        <span class="text-center text-color">&copy; copyright 2020. All Right Reserved</span>
      </div>
    );
  },
});
